import React, { useState, useRef, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import PageTile from "../../../../components/pageTitle/pageTitle/PageTile";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaUpload } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { updateBlog } from "../../../../services/admin/CmsPages/cmsPagesServices";
import { adminPathUrl } from "../../../../shared/pathUrl";
import { toast } from "react-toastify";
import { resizeImage } from "../../../../utils/methods";
import ImageResolutionWarningModal from "../../../../components/ImageCompressModal";
import Description from "../../../../components/Description";
import MaxLimit from "../../../../components/MaxLimit";

// Define max lengths for each field
const MAX_LENGTHS = {
  title: 60,
  sub_title: 300,
  content: 6000, // Adjust as needed
};

function UpdateBlogs() {
  const [image, setImage] = useState(null);
  const [tempFile, setTempFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20 MB
  const maxWidth = 380;
  const maxHeight = 200;

  const [initialValues, setInitialValues] = useState({
    title: "",
    sub_title: "",
    content: "",
    blog_pic: null,
  });

  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  useEffect(() => {
    if (state && state.blog) {
      setInitialValues({
        title: state.blog.title,
        sub_title: state.blog.subTitle,
        content: state.blog.content,
        blog_pic: state.blog.blog_short_pic,
      });
      setImage(state.blog.blog_short_pic);
      setImageUrl(state.blog.blog_short_pic);
    }
  }, [state]);

  const validationSchema = Yup.object({
    title: Yup.string().max(MAX_LENGTHS.title, `Title cannot exceed ${MAX_LENGTHS.title} characters`).required("Title is required"),
    sub_title: Yup.string().max(MAX_LENGTHS.sub_title, `Quoted Text cannot exceed ${MAX_LENGTHS.sub_title} characters`),
    content: Yup.string().max(MAX_LENGTHS.content, `Content cannot exceed ${MAX_LENGTHS.content} characters`).required("Content is required"),
    blog_pic: Yup.mixed().required("Image is required"),
  });

  const handleEditorChange = (value, setFieldValue) => {
    setFieldValue("content", value);
  };

  const handleCancel = () => {
    navigate(adminPathUrl.manageBlogs);
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = async (e, setFieldValue) => {
    const file = e.target.files[0];
    if (!file) return;

    if (file.size > MAX_FILE_SIZE) {
      toast.error(
        "File size exceeds 20 MB limit. Please select a smaller file."
      );
      return;
    }

    const img = new Image();
    const reader = new FileReader();

    reader.onload = () => {
      img.src = reader.result;
    };

    img.onload = async () => {
      const { width, height } = img;

      if (width > maxWidth || height > maxHeight) {
        setTempFile(file);
        setShowModal(true);
      } else {
        setImage(file);
        setImageUrl(URL.createObjectURL(file));
        setFieldValue("blog_pic", file);
      }
    };

    reader.readAsDataURL(file);
  };

  const handleCompressImage = async (setFieldValue) => {
    if (!tempFile) return;

    const resizedFile = await resizeImage(tempFile, maxWidth, maxHeight);

    setImage(resizedFile);
    setImageUrl(URL.createObjectURL(resizedFile));
    setShowModal(false);

    // Update Formik's field value with the compressed image
    setFieldValue("blog_pic", resizedFile);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setTempFile(null);
    fileInputRef.current.value = "";
  };

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("content", values.content);
    formData.append("id", state.blog._id);
    formData.append("subTitle", values.sub_title);
    if (values.blog_pic) {
      formData.append("blog_pic", values.blog_pic);
    }

    try {
      const response = await updateBlog(formData);
      if (response.status) {
        toast.success("Blog updated successfully!");
        navigate(adminPathUrl.manageBlogs);
      } else {
        toast.error("Failed to update blog. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <div className="manage_blogs_page shadow card p-4 rounded">
      <PageTile heading1={"Update Insights"} backButton={true} />
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <div className="form-group">
              <label htmlFor="title">Title</label>
              <MaxLimit
                  current={values.title.length}
                  max={MAX_LENGTHS.title}
                />
              <Field
                type="text"
                name="title"
                className="form-control"
                maxLength={MAX_LENGTHS.title}
              />
              <ErrorMessage
                name="title"
                component="div"
                className="text-danger"
              />
           
            </div>
            <div className="form-group">
              <label htmlFor="sub_title">Quoted Text</label>
              <MaxLimit
                  current={values.sub_title.length}
                  max={MAX_LENGTHS.sub_title}
                />
                
              <Field
                type="text"
                name="sub_title"
                className="form-control"
                maxLength={MAX_LENGTHS.sub_title}
              />
            
            </div>
            <Description
              message={`Please upload an image with a resolution of (${maxWidth} × ${maxHeight}) pixels.`}
            />
            {imageUrl && (
              <img
                src={imageUrl}
                alt="Selected"
                style={{ maxWidth: "100%", marginTop: "10px" }}
              />
            )}
            <div className="input-group mb-3">
              <input
                type="file"
                name="blog_pic"
                id="blog_pic"
                onChange={(e) => handleImageChange(e, setFieldValue)}
                ref={fileInputRef}
                style={{ display: "none" }}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={() => fileInputRef.current.click()}
                >
                  Upload Image
                </button>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="content">Content</label>
              
              <MaxLimit
                  current={values.content.length}
                  max={MAX_LENGTHS.content}
                />
              <Field
                as="textarea"
                name="content"
                className="form-control"
                rows={8}
                maxLength={MAX_LENGTHS.content}
                onChange={(e) =>
                  handleEditorChange(e.target.value, setFieldValue)
                }
              />
              <ErrorMessage
                name="content"
                component="div"
                className="text-danger"
              />
             
            </div>

            <div className="py-3">
              <button type="submit" className="global_admin_btn">
                Update
              </button>
              <button
                type="button"
                className="global_admin_btn btn py-2 px-4 btn-danger ms-2"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>

            <ImageResolutionWarningModal
              show={showModal}
              onHide={handleCloseModal}
              onCompress={() => handleCompressImage(setFieldValue)}
              resolution={`${maxWidth}x${maxHeight}`}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default UpdateBlogs;

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import "./profile.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  getMyProfileById,
  updateMyProfileService,
} from "../../../services/admin/manageworkforce/manageworkforceservices";
import { adminPathUrl } from "../../../shared/pathUrl";
import Swal from "sweetalert2";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons
import PageTile from "../../../components/pageTitle/pageTitle/PageTile";

const Profile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [previewImage, setPreviewImage] = useState(null);
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    profilePic: null,
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [oldPasswordVisible, setOldPasswordVisible] = useState(false);

  useEffect(() => {
    const getProfileData = async () => {
      try {
        const response = await getMyProfileById(id);
        const { fullName, email, admin_pic, phone } = response.data.data;

        // Split fullName into firstName and lastName
        const [firstName, ...lastNameParts] = fullName.split(" ");
        const lastName = lastNameParts.join(" ");

        setInitialValues({
          firstName: firstName || "",
          lastName: lastName || "",
          email: email || "",
          phone: phone || "",
          profilePic: null,
          oldPassword: "",
          password: "",
          confirmPassword: "",
        });

        if (admin_pic) {
          setPreviewImage(admin_pic);
        }
      } catch (error) {
        console.error("Failed to load profile data", error);
      }
    };

    getProfileData();
  }, [id]);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const fullName = `${values.firstName} ${values.lastName}`.trim();
      const data = {
        _id: id,
        fullName: fullName,
        email: values.email,
        phone: values.phone,
        profilePic: previewImage || null,
        oldPassword: values.oldPassword,
        newPassword: values.password,
        confirmPassword: values.confirmPassword,
      };

      const response = await updateMyProfileService(id, data);

      // Update local state with the new values
      setInitialValues({
        firstName: response.data.fullName.split(" ")[0] || "",
        lastName: response.data.fullName.split(" ").slice(1).join(" ") || "",
        email: response.data.email,
        phone: response.data.phone,
        profilePic: response.data.admin_pic,
        oldPassword: "",
        password: "",
        confirmPassword: "",
      });

      resetForm();

      // Show SweetAlert for success
      Swal.fire({
        title: "Profile Updated!",
        text: "Your profile has been updated successfully.",
        icon: "success",
        confirmButtonText: "OK",
      }).then(() => {
        // Reload the page and then redirect to dashboard
        window.location.reload();
        window.location.href = adminPathUrl.dashboard;
      });
    } catch (error) {
      console.error("Error updating profile:", error);
      // Show an error alert if needed
      Swal.fire({
        title: "Error!",
        text: "There was an error updating your profile. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.firstName) errors.firstName = "First name is required";
    if (!values.lastName) errors.lastName = "Last name is required";
    if (!values.email) errors.email = "Email is required";
    else if (!/^\S+@\S+\.\S+$/.test(values.email))
      errors.email = "Invalid email address";
    if (values.oldPassword && values.oldPassword.length < 6)
      errors.oldPassword = "Old password must be at least 6 characters";
    if (values.password && values.password.length < 6)
      errors.password = "Password must be at least 6 characters";
    if (values.password !== values.confirmPassword)
      errors.confirmPassword = "Passwords must match";
    return errors;
  };

  const handleFileChange = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    setFieldValue("profilePic", file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const toggleOldPasswordVisibility = () => {
    setOldPasswordVisible(!oldPasswordVisible);
  };

  const goToHomepage = () => {
    navigate(adminPathUrl.dashboard); // Adjust the path as needed
  };

  return (
    <div className="container-fluid">
      <div className="content_wrapper">
        
        <div className="card shadow p-5 m-5">
        <PageTile  backButton={true} />

          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            validate={validate}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form>
                <div className="row">

                  <div className="col-md-4 form-group profile_pic_update">
                    <div className="img_block">
                      {previewImage ? (
                        <img
                          src={previewImage}
                          alt="Profile"
                          className="thumbnail"
                        />
                      ) : (
                        <img
                          src="https://via.placeholder.com/150"
                          alt="Placeholder"
                          className="thumbnail"
                        />
                      )}
                    </div>
                    <div>
                      <input
                        type="file"
                        id="profilePic"
                        name="profilePic"
                        onChange={(event) =>
                          handleFileChange(event, setFieldValue)
                        }
                        className="form-control-file d-none"
                      />
                      <label htmlFor="profilePic" className="global_admin_btn">
                        Replace Image
                      </label>
                      <ErrorMessage
                        name="profilePic"
                        component="div"
                        className="error"
                        style={{ color: "red" }}
                      />
                    </div>
                  </div>
                  <div className="col-md-8 ps-4">
                    <h2 className="text-center pb-4">My Profile</h2>
                    <div className="form-group">
                      <div className="form-row row">
                        <div className="col-md-6">
                          <Field
                            type="text"
                            id="firstName"
                            placeholder="First Name"
                            name="firstName"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="firstName"
                            component="div"
                            className="error"
                            style={{ color: "red" }}
                          />
                        </div>
                        <div className="col-md-6">
                          <Field
                            type="text"
                            id="lastName"
                            placeholder="Last Name"
                            name="lastName"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="lastName"
                            component="div"
                            className="error"
                            style={{ color: "red" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-row row">
                      <div className="form-group col-md-6">
                        <Field
                          type="email"
                          id="email"
                          placeholder="Email"
                          name="email"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error"
                          style={{ color: "red" }}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <Field
                          type="text"
                          id="phone"
                          placeholder="Phone"
                          name="phone"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="error"
                          style={{ color: "red" }}
                        />
                      </div>
                    </div>

                    {/* New Password and Confirm Password Fields */}
                    <div className="form-row row">
                      <div className="form-group col-md-6 position-relative">
                        <Field
                          type={oldPasswordVisible ? "text" : "password"}
                          id="oldPassword"
                          placeholder="Old Password"
                          name="oldPassword"
                          className="form-control"
                        />
                        <span
                          className="profile-toggle-password"
                          onClick={toggleOldPasswordVisibility}
                        >
                          {oldPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                        </span>
                        <ErrorMessage
                          name="oldPassword"
                          component="div"
                          className="error"
                          style={{ color: "red" }}
                        />
                      </div>
                      <div className="form-group col-md-6 position-relative">
                        <Field
                          type={passwordVisible ? "text" : "password"}
                          id="password"
                          placeholder="New Password"
                          name="password"
                          className="form-control"
                        />
                        <span
                          className="profile-toggle-password"
                          onClick={togglePasswordVisibility}
                        >
                          {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                        </span>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="error"
                          style={{ color: "red" }}
                        />
                      </div>
                      <div className="form-group col-md-6 position-relative">
                        <Field
                          type={confirmPasswordVisible ? "text" : "password"}
                          id="confirmPassword"
                          placeholder="Confirm New Password"
                          name="confirmPassword"
                          className="form-control"
                        />
                        <span
                          className="profile-toggle-password"
                          onClick={toggleConfirmPasswordVisibility}
                        >
                          {confirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                        </span>
                        <ErrorMessage
                          name="confirmPassword"
                          component="div"
                          className="error"
                          style={{ color: "red" }}
                        />
                      </div>
                    </div>

                    <div className="text-center">
                      <button
                        type="submit"
                        className="global_admin_btn"
                        disabled={isSubmitting}
                      >
                        Update
                      </button>
                      <button
                        type="button"
                        className="global_admin_btn btn py-2 px-4 btn-secondary ms-2"
                        onClick={goToHomepage}
                      >
                        Back to Homepage
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Profile;

import React, { useEffect, useState } from "react";
import "../../pages/cart/index.css";
import labor from "../../assests/images/labor.jpg";
import doc1 from "../../assests/admin-images/docs/degree.jpg";
import doc2 from "../../assests/admin-images/docs/passport.jpg";
import doc3 from "../../assests/admin-images/docs/resume.jpg";
import { useLocation } from "react-router-dom";
import { getWorkerDetail, verifyWorkforceUser } from "../../services/admin/manageworkforce/manageworkforceservices";
import { toast } from "react-toastify";
import PageTile from "../../components/pageTitle/pageTitle/PageTile";
import Modal from "../../components/imageModal";

export default function DataLakeWorkerForceDetail() {
  const [editIndex, setEditIndex] = useState(-1);
  const [imageSources, setImageSources] = useState([doc1, doc2, doc3]);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ verified: false, unverified: false, rejected: false });
  const [selectedImage, setSelectedImage] = useState(null); // State to manage the image popup
  const location = useLocation();

  const handleEditClick = (index) => {
    setEditIndex(index);
    document.getElementById(`file-input-${index}`).click();
  };

  const handleFileChange = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const newImageSources = [...imageSources];
        newImageSources[index] = reader.result;
        setImageSources(newImageSources);
        setEditIndex(-1);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCheckboxChange = (event) => {
    const { name } = event.target;

    setStatus({
      verified: name === 'verified',
      unverified: name === 'unverified',
      rejected: name === 'rejected'
    });
  };

  const handleSave = async () => {
    try {
      // Determine status string with first letter capitalized
      let statusString = '';
      if (status.verified) statusString = 'Verified';
      else if (status.unverified) statusString = 'Unverified';
      else if (status.rejected) statusString = 'Rejected';
      
      const body = {
        is_admin_verified: statusString, // Send status as a capitalized string
      };

      // Call API to update the status
      const response = await verifyWorkforceUser({ body, id: data._id });
      if (response.status) {
        toast.success("Status updated successfully!");
      } else {
        toast.error("Failed to update status.");
      }
    } catch (error) {
      toast.error("Error saving status.");
    }
  };

  useEffect(() => {
    const fetchWorkerDetail = async () => {
      try {
        setLoading(true);
        const body = {
          user_id: location.state.id,
        };
        const response = await getWorkerDetail(body);
        if (response && response.data) {
          const workerData = response.data.data;
          setData(workerData);
  
          // Determine status based on `is_admin_verified` value
          setStatus({
            verified: workerData.is_admin_verified === 'Verified',
            unverified: workerData.is_admin_verified === 'Unverified',
            rejected: workerData.is_admin_verified === 'Rejected'
          });
        } else {
          toast.error("No data available.");
        }
      } catch (error) {
        toast.error("Error fetching data.");
      } finally {
        setLoading(false);
      }
    };
  
    fetchWorkerDetail();
  }, [location.state.id]);

  return (
    <section>
      <div className="container">
        
        <div className="prev-detail">
          <PageTile heading1={"Worker Details"} backButton={true}/>
         
          <div className="row">
            <div className="col-md-4">
              <div className="position-relative text-center py-5 bg-light shadow rounded">
                <div className="profileName">
                  <img className="" src={data?.profile_pic} alt="Worker"/>
                </div>
                <div className="modal-detail-content">
                  <h1>{data?.fullName}</h1>
                  <h2>
                    {data?.skill?.[0]?.SubSkillName} | {data?.nationality} | {data?.city}
                  </h2>
                  <h3 className="border-top">Skills with Experience</h3>
                  {data?.skill.map((skill, index) => (
                    <div className="modal-detail-content-exp pb-3" key={index}>
                      {skill?.SubSkillName} ({skill?.experience}) |   {skill?.skillName} <br />
                    </div>
                  ))}
                  <div className="modal-detail-content-personal pt-3 border-top">
                    Age: <span>{data?.age}</span> <br />
                    Language: <span>{data?.language}</span> <br />

                    Passport: <span className="text-capitalize">{data?.is_passport}</span> <br/>
                    Phone: <span className="text-capitalize">{data?.phone}</span>

                  </div>
                </div>
              </div>
            </div>
            
          
          

            <div className="col-md-8">
              <div className="doc-card position-relative p-2 bg-light shadow rounded h-100">
                <div className="image-edit-section">
                  <h3>Documents</h3>
                  <div className="row">
                    {data?.documents?.map((item, index) => (
                      <div key={index} className="col-md-4">
                        <div className="position-relative bg-light shadow rounded 1">
                          <img
                            style={{ borderRadius: "10px", cursor: 'pointer' }}
                            className="img-fluid w-100"
                            src={item?.user_document}
                            alt={`Document ${index}`}
                            onClick={() => setSelectedImage(item?.user_document)} // Show image in modal
                          />
                          <p className="text-center">{item?.doc_title}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="status-section pt-0 pb-3">
            <h5 className="mt-4">Status</h5>
            <div className="d-flex flex-wrap gap-4 align-items-center">
              <div className="form-check" style={{ opacity: 0.8 }}>
                <input
                  type="checkbox"
                  className="form-check-input opacity-1"
                  id="verified"
                  name="verified"
                  checked={status.verified}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="verified">
                  Verified
                </label>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input opacity-1"
                  id="unverified"
                  name="unverified"
                  checked={status.unverified}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="unverified">
                  Unverified
                </label>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input opacity-1"
                  id="rejected"
                  name="rejected"
                  checked={status.rejected}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="rejected">
                  Rejected
                </label>
              </div>
              <button className="btn btn-primary btn-global" onClick={handleSave}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      {selectedImage && (
        <Modal imageSrc={selectedImage} onClose={() => setSelectedImage(null)} />
      )}
    </section>
  );
}

import React, { useState } from "react";
import { Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Pagination from "../pagination/pagination";

const DataTableComponent = ({
  title,
  data,
  columns,
  selectedRows,
  clickedrow,
  checkboxchange,
  downloadBtns = false,
  downloadCSV = () => {},
  downloadPDF = () => {},
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

  const totalItems = data.length;

  // Calculate the range for the current page
  const startItem = indexOfFirstItem + 1;
  const endItem = Math.min(indexOfLastItem, totalItems);

  // Determine if pagination should be shown
  const showPagination = totalItems > itemsPerPage;

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Card style={{ border: "none" }}>
      <div style={{ padding: "20px" }}>
        <h3 className="px-2 card_title">
          {title}({`${startItem}-${endItem} of ${totalItems}`})
          {downloadBtns && (
            <div className="d-flex justify-content-end mb-3 download_btns">
              <button className="global_admin_btn me-2" onClick={downloadPDF}>
                Download PDF
              </button>
              <button
                onClick={downloadCSV}
                filename={"Manpower Enquiry.csv"}
                className="global_admin_btn"
              >
                Download CSV
              </button>
            </div>
          )}
        </h3>

        <DataTable
          className="table_block mt-3"
          highlightOnHover={true}
          responsive={true}
          selectableRowsHighlight={true}
          onRowClicked={clickedrow}
          onSelectedRowsChange={checkboxchange}
          columns={columns}
          pagination={false} // Disable default pagination
          customStyles={{
            table: {
              style: {
                border: "1px solid white",
              },
            },
            headCells: {
              style: {
                color: "#A8A8BD",
                fontSize: 16,
              },
            },
            rows: {
              style: {
                borderBottom: "1px solid #C5C5D3",
                padding: 20,
              },
            },
          }}
          data={currentItems}
          // selectableRows={selectedRows}
        />

        {/* Render Pagination component only if there are more than itemsPerPage entries */}
        {showPagination && (
          <Pagination
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={totalItems}
            paginate={handlePageChange}
          />
        )}
      </div>
    </Card>
  );
};

export default DataTableComponent;

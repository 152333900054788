import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { GoArrowUpRight } from "react-icons/go";
import blog1 from "../../assests/images/Group 9.jpg";
import blog2 from "../../assests/images/Group 10.jpg";
import blog3 from "../../assests/images/Group 7.jpg";
import { pathUrl } from "../../shared/pathUrl";
import { getTheBlog } from "../../services/web/blog/blogServices";
import SmallBlogCard from "./smallBlogCard";

const BlogPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [blogData, setBlogData] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState(null);

  useEffect(() => {
    setSelectedBlog(location.state);
  }, [location.state]);

  const handleReadMore = (id) => {
    navigate(`${pathUrl.blogDetail}/${id}`);
  };

  const getTheBlogData = async () => {
    try {
      const getData = await getTheBlog();
      if (getData?.status) {
        setBlogData(getData?.data);
      }
    } catch (error) {
      console.log("exceptional error", error);
    }
  };

  useEffect(() => {
    getTheBlogData();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString || isNaN(Date.parse(dateString))) {
      return "Invalid Date";
    }

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      // second: 'numeric',
      hour12: true,
    };

    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', options).format(date);
  };

  return (
    <>
      <section className="mt-xl-5">
        <div className="container">
          <div className="row">
            <div className="col-xl-6" style={{ backgroundColor: "#eafcfa" }}>
              <div className="blog-banner-section-content">
                <span>{blogData && formatDate(blogData[0]?.createdAt)}</span>
                <h5>{blogData && blogData[0]?.title}</h5>
                <p>{blogData && blogData[0]?.content?.slice(0, 160)}...</p>
                <Button onClick={() => handleReadMore(blogData[0]?._id)} className="read-more-btn">
                  Read More
                </Button>
              </div>
            </div>
            <div className="col-xl-6 blog_img_1 p-0">
              <img
                className="img-fluid"
                src={blogData && blogData[0]?.blog_large_pic}
                alt="Insights Banner"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mt-5 margin-bottom">
        <div className="container">
          <div className="row">
            {blogData && blogData?.map((item) => (
              <SmallBlogCard
                key={item._id}
                cardData={item}
                onClick={() => handleReadMore(item._id)}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogPage;

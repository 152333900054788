import React, { useState, useEffect } from "react";
import { FaTrash, FaUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { adminPathUrl } from "../../shared/pathUrl";
import { useDispatch } from "react-redux";
import { IoSearchSharp } from "react-icons/io5";
import {
  getskillset,
  getworkforcelisting,
  verifyWorkforceUser,
} from "../../services/admin/manageworkforce/manageworkforceservices";
import DataTableComponent from "../../components/dataTable/dataTable";
import DeleteModalAdmin from "../../components/deleteModalAdmin";
import Loader from "../../components/loader/loader";
import { getCategory } from "../../services/web/manpowerEnquiry/manpowerEnquiryservices";
import "./workforce.css";
import "../../index.css";
import { downloadCSV, downloadPDF, getAllSkills } from "../../utils/methods";
import Avatar from "../../components/Avatar";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const DataLakeWorkForce = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [skillFilter, setSkillFilter] = useState("");
  const [experienceFilter, setExperienceFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [listData, setListData] = useState([]);
  const [skillsetData, setSkillsetData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [skillList, setSkillList] = useState([]);
  const [verificationFilter, setVerificationFilter] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formatDateTime = (date) => {
    if (!date) return "";
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const userColumns = [
    {
      name: "",
      selector: (row) => <Avatar imgSrc={row.profile_pic} />,
    },
    {
      name: "Name",
      selector: (row) => row.fullName,
      sortable: true,
    },

    {
      name: "Phone",
      selector: (row) => {
        const countryCode = row.countryCode;
        const phoneNumber = row.phone;
        return `${countryCode} ${phoneNumber}`;
      },
      sortable: true,
    },
    {
      name: "Age",
      selector: (row) => row.age,
      sortable: true,
    },
    {
      name: "Skills with Experience",
      selector: (row) => (
        <div className="experience_col">
          {row.skills.map((item, index) => (
            <p key={index}>
              {item?.SubSkillName} with {item.experience}
            </p>
          ))}
        </div>
      ),
      sortable: true,
      width: "250px",
    },
    {
      name: "Account Status",
      selector: (row) => (
        <span
          style={{
            color: row.account_status === "Active" ? "green" : "red",
          }}
        >
          {row.account_status}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Date & Time",
      selector: (row) => formatDateTime(row.createdAt),
      sortable: true,
      width: "250px",
    },
    {
      name: "Verification Status",
      selector: (row) => (
        <span
          style={{
            color:
              row.is_admin_verified === "Verified"
                ? "green"
                : row.is_admin_verified === "Unverified"
                ? "darkgoldenrod"
                : row.is_admin_verified === "Rejected"
                ? "red"
                : "black",
          }}
        >
          {row.is_admin_verified}
        </span>
      ),
      sortable: true,
      width: "250px",
    },
  ];

  const onVerifyUser = (row, value) => {
    const body = {
      is_admin_verified: value,
    };
    verifyWorkforceUser({ body, id: row._id }).then((res) => {
      if (res.status) {
        fetchWorkforceData();
      }
    });
  };

  useEffect(() => {
    const fetchSkillset = async () => {
      try {
        const data = await getskillset({ body: null, type: "data_lake" });
        setSkillsetData(data);
      } catch (error) {
        console.error("Error fetching skillset:", error);
      }
    };
    fetchSkillset();
  }, []);

  useEffect(() => {
    getCategory()
      .then((res) => setSkillList(res.data))
      .catch((err) => console.log(err));
  }, []);

  const fetchWorkforceData = async () => {
    setLoading(true); // Set loading to true before fetching data
    try {
      const response = await getworkforcelisting({
        body: null,
        type: "data_lake",
      });
      if (response?.data && response?.status) {
        const withoutVerifiedUsers = response.data.data.filter(
          (item) => item.is_admin_verified !== "Verified"
        );
        setListData(withoutVerifiedUsers); // Update listData state with fetched data
        setFilteredData(withoutVerifiedUsers); // Initialize filteredData with fetched data
      }
    } catch (error) {
      console.error("Error fetching workforce data:", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  useEffect(() => {
    fetchWorkforceData();
  }, []);

  useEffect(() => {
    let filtered = listData;

    // Filter by skill
    if (skillFilter) {
      filtered = filtered.filter((item) =>
        item.skills.some((skill) => skill.SubSkillName === skillFilter)
      );
    }

    // Filter by experience
    if (experienceFilter) {
      filtered = filtered.filter((item) => {
        const experienceYears = item.skills.reduce(
          (acc, skill) => acc + getExperienceYears(skill.experience),
          0
        );
        switch (experienceFilter) {
          case "0-1":
            return experienceYears >= 0 && experienceYears <= 1;
          case "1-2":
            return experienceYears > 1 && experienceYears <= 2;
          case "2-4":
            return experienceYears > 2 && experienceYears <= 4;
          case "4+":
            return experienceYears > 4;
          default:
            return true;
        }
      });
    }

    // Filter by search term
    if (searchTerm) {
      filtered = filtered.filter(
        (item) =>
          item?.fullName?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
          item?.phone?.toLowerCase()?.includes(searchTerm?.toLowerCase())
      );
    }

    // Filter by verification status
    if (verificationFilter) {
      filtered = filtered.filter(
        (item) => item.is_admin_verified === verificationFilter
      );
    }

    setFilteredData(filtered);
  }, [skillFilter, experienceFilter, searchTerm, verificationFilter, listData]);

  const handleRowClicked = (row) => {
    navigate(adminPathUrl.dataLakeWorkforceDetail, { state: { id: row._id } });
  };

  const handleCheckboxChange = (selectedRows) => {
    setSelectedRows(selectedRows.selectedRows);
  };

  const handleAddUserClick = () => {
    navigate(adminPathUrl.addDataWorkforce);
  };

  const handleSkillFilterChange = (event) => {
    setSkillFilter(event.target.value);
  };

  const handleExperienceFilterChange = (event) => {
    setExperienceFilter(event.target.value);
  };

  const handleVerificationFilterChange = (event) => {
    setVerificationFilter(event.target.value);
  };

  const getExperienceYears = (skill) => {
    const match = skill.match(/(\d+) years/);
    return match ? parseInt(match[1], 10) : 0;
  };

  if (loading) {
    return <Loader />;
  }

  const subSkillNames = getAllSkills(skillsetData.data);
  const pdfHeaders = [
    "Name",
    "Phone",
    "Age",
    "Skills with Experience",
    "Account Status",
    "Date & Time",
    "Verification Status",
  ];

  const pdfData = filteredData.map((row) => [
    row.fullName,
    `${row.countryCode} ${row.phone}`,
    row.age,
    row.skills
      .map((item) => `${item.SubSkillName} with ${item.experience}`)
      .join(", "),
    row.account_status,
    formatDateTime(row.createdAt),
    row.is_admin_verified,
  ]);

  const pdfFileName = "Manage_profiles.pdf";
  const csvFileName = "Manage_profiles.csv";

  const csvData = filteredData.map((row) => ({
    Name: row.fullName,
    Phone: `${row.countryCode} ${row.phone}`,
    Age: row.age,
    "Skills with Experience": row.skills
      .map((item) => `${item.SubSkillName} with ${item.experience}`)
      .join(", "),
    "Account Status": row.account_status,
    "Date & Time": formatDateTime(row.createdAt),
    "Verification Status": row.is_admin_verified,
  }));
  return (
    <div className="user_page">
      <div className="d-flex justify-content-between align-items-center mt-2 mb-4">
        <div className="mb-0">
          <input
            className="searchbar"
            type="search"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <IoSearchSharp className="searchbar-icon" size={44} />
        </div>

        <div className="filters_group text-end mt-2 col-md-6 gap-2">
          <FormControl fullWidth>
            <InputLabel id="skill-filter-label">Skill</InputLabel>
            <Select
              labelId="skill-filter-label"
              value={skillFilter}
              onChange={handleSkillFilterChange}
              label="Skill"
            >
              <MenuItem value="">All Skills</MenuItem>
              {subSkillNames?.map((skill, index) => (
                <MenuItem key={index} value={skill}>
                  {skill}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="experience-filter-label">Experience</InputLabel>
            <Select
              labelId="experience-filter-label"
              value={experienceFilter}
              onChange={handleExperienceFilterChange}
              label="Experience"
            >
              <MenuItem value="">All Experiences</MenuItem>
              <MenuItem value="0-1">0-1 years</MenuItem>
              <MenuItem value="1-2">1-2 years</MenuItem>
              <MenuItem value="2-4">2-4 years</MenuItem>
              <MenuItem value="4+">4+ years</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="verification-filter-label">Verification</InputLabel>
            <Select
              labelId="verification-filter-label"
              value={verificationFilter}
              onChange={handleVerificationFilterChange}
              label="Verification"
            >
              <MenuItem value="">All Verification Status</MenuItem>
              {/* <MenuItem value="Verified">Verified</MenuItem> */}
              <MenuItem value="Unverified">Unverified</MenuItem>
              <MenuItem value="Rejected">Rejected</MenuItem>
            </Select>
          </FormControl>

          <button
            onClick={() =>
              navigate(adminPathUrl.addDataWorkforce, { state: skillList })
            }
            className="add-user-button m-0 d-flex align-items-center gap-2"
          >
            <FaUser /> <span>Add Workforce</span>
          </button>
        </div>
      </div>

      <div className="datatable-section manage-card">
      <DataTableComponent
        title="Manage Profiles"
        data={filteredData}
        columns={userColumns}
        selectedRows={selectedRows}
        clickedrow={handleRowClicked}
        checkboxchange={handleCheckboxChange}
        responsive={true}
        downloadBtns={true}
        downloadCSV={() => downloadCSV(csvData, csvFileName)}
        downloadPDF={() => downloadPDF(pdfHeaders, pdfData, pdfFileName)}
        pdfHeaders={pdfHeaders}
      />
      </div>

      <DeleteModalAdmin
        open={deleteModal}
        setOpen={setDeleteModal}
        heading={"Are you sure to delete this Worker"}
      />
    </div>
  );
};

export default DataLakeWorkForce;

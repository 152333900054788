import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { IoInformationCircleOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import { toast } from "react-toastify";
import ClientReview from "../../components/clientReview/clientReview";
import { manPowerEnquirySlice } from "../../redux/manpowerEnquiry/manpowerEnquirySLice";
import {
  getCategory,
  getTheWorkerAsPerCategory,
  submitManpowerDataDromthecartPage,
} from "../../services/web/manpowerEnquiry/manpowerEnquiryservices";
import { countryOnTopList } from "../../shared/constant";
import {
  fetchCountriesAndCities,
  globalValidationFunction,
} from "../../shared/helperfunction";
import { pathUrl } from "../../shared/pathUrl";
import "./index.css";
import IndustryInsights from "./industryInsights";
import IndustryServe from "./industryServe";
import QualifiedWorkerSection from "./qualifiedWorkerSection";
import SkillSetSection from "./skillSetSection";
import RadioComp from "../../components/RadioComp";
import ThanksModal from "../cart/thanksModal";
import {
  getGulfHomepageService,
  getReviewsService,
} from "../../services/admin/manageworkforce/manageworkforceservices";
import usePageRefresh from "../../hooks/usePageRefresh";
import PhoneInputComp2 from "../../components/PhoneInput2";
import Tooltip from "../../components/Tooltip";
import CategoriesSection from "../../components/CategoriesSection";
import ConfirmationPupup from "../../components/confirmPupUp";
import Footer from "../../components/footer";
import HandleHash from "../../components/HandleHash";
import {
  getBannersList,
  getSkillSetList,
} from "../../services/admin/manageworkforce/manageWorkEnquiryServices";
import Loader from "../../components/loader/loader";

const longTerm = "long_term";
const shortTerm = "short_term";

const initFormData = {
  name: {
    value: "",
    warning: false,
  },
  email: {
    value: "",
    warning: false,
  },
  contactNumber: {
    value: "",
    warning: false,
  },
  companyName: {
    value: "",
    warning: false,
  },
  country: {
    value: "",
    warning: false,
  },
  city: {
    value: "",
    warning: false,
  },
  accomodation: {
    value: "",
    warning: false,
  },
  transportation: {
    value: "",
    warning: false,
  },
  message: {
    value: "",
    warning: false,
  },
  tenure: {
    value: "",
    warning: false,
  },
  userType: {
    value: "",
    warning: false,
  },
  categoryWorkerRequired: [],
};
export default function Homepage() {
  const [step, setStep] = useState(1);
  const [openPopup, setOpenPopup] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [banners, setBanners] = useState([]);
  const [countriesWithCities, setCountriesWithCities] = useState([]);
  const [city, setCity] = useState([]);
  const [formDataValue, setFormDataValue] = useState(initFormData);
  const [selectedOption, setSelectedOption] = useState("");
  const [open, setOpen] = useState(false);
  const [prevSelectedOption, setPrevSelectedOption] = useState("");
  const [isBack, setIsBack] = useState(false);
  const [homepage, setHomepage] = useState([]);
  const [paused, setPaused] = useState(false);
  const [pause, setPause] = useState(false);
  const [isButtonHovered, setIsButtonHovered] = useState(false);
  const [loading, setLoading] = useState(true);


  
  // Function to handle when mouse enters the button
  const handleMouseEnter = () => {
    setIsButtonHovered(true); // Set state to true when button is hovered
  };

  // Function to handle when mouse leaves the button
  const handleMouseLeave = () => {
    setIsButtonHovered(false); // Set state to false when button is not hovered
  };
  const handleSearchClick = () => {
    setPause(true); // Set state to pause the carousel
  };
  //Redux state
  const { categoryTable, selectedCart } = useSelector(
    (state) => state.MANPOWERENQUIRY
  );

  //Redux functions
  const dispatch = useDispatch();

  //Router functions
  const location = useLocation();
  const navigate = useNavigate();

  //Custom hook
  const { isPageRefreshed } = usePageRefresh(location);

  useEffect(() => {
    if (location?.state?.edit && !isPageRefreshed) {
      setFormDataValue(location.state.formData);
      setSelectedOption(location.state.formData.tenure.value);
    } else {
      dispatch(manPowerEnquirySlice.actions.slectedCategoriesCart([]));
    }
  }, []);
  useEffect(() => {
    setFormDataValue((prev) => ({
      ...prev,
      categoryWorkerRequired: categoryTable,
    }));
  }, [categoryTable]);

  const GetReviewsData = async () => {
    const response = await getReviewsService();
    if (response.status) {
      setReviews(response.data);
    }
  };

  const GetSkillSetData = async () => {
    const response = await getSkillSetList();
    if (response.status) {
      setSkillList(response.data);
    }
  };

  // const GetBannersData = async () => {
  //   const response = await getBannersList();
  //   if (response.status) {
  //     setBanners(response.data);
  //   }
  // };
  const GetBannersData = async () => {
    setLoading(true); // Start loading
    const response = await getBannersList();
    if (response.status) {
      setBanners(response.data);
    }
    setLoading(false); // End loading
  };

 

 

  const getHomepageServiceData = async () => {
    try {
      const response = await getGulfHomepageService();
      if (response.status) {
        setHomepage(response.data);
      }
    } catch (error) {
      console.error("Error fetching reviews:", error);
    }
  };

  useEffect(() => {
    getCountryCity();
    GetReviewsData();
    GetSkillSetData();
    GetBannersData();
    getHomepageServiceData();
  }, []);

  useEffect(() => {
    if (step === 1 && !location?.state?.edit && !isBack) {
      getCategoryFromBackend();
    }
  }, [step]);

  useEffect(() => {
    getTheCityOfParticularCountry();
  }, [formDataValue?.country?.value, countriesWithCities]);

  
  //Methods
  const handleRadioChange = (event) => {
    const { value } = event.target;
    setPrevSelectedOption(selectedOption);
    if (selectedOption !== "" && selectedCart.length > 0) {
      setOpen(true);
    }
    setSelectedOption(value);
    setFormDataValue((prev) => ({
      ...prev,
      tenure: { value: value, warning: value === "" },
    }));
  };

  const onChange = (e) => {
    const { name, value } = e.target;

    if (name === "name") {
      const regex = /^[a-zA-Z\s]*$/; // Allows only letters and spaces
      if (!regex.test(value)) {
        // If the value doesn't match the regex, don't update the state
        setFormDataValue((prevState) => ({
          ...prevState,
          [name]: {
            value: value,
            warning: "Special characters and numbers are not allowed",
          },
        }));
        return;
      }
    }

    // Update the state if validation passes or for other fields
    setFormDataValue((prevState) => ({
      ...prevState,
      [name]: { value: value, warning: "" },
    }));
  };

  const handlePhoneChange = (value) => {
    setFormDataValue((prev) => ({
      ...prev,
      contactNumber: {
        value: value,
        warning: value === "" || value === undefined,
      },
    }));
  };
  //on submit function of the form
  const onSubmit = async (event) => {
    event.preventDefault();
    let data = { ...formDataValue };
    let tempData = {};
    if (step === 1) {
      if (selectedOption === shortTerm) {
        tempData = data;
      } else {
        tempData = {
          ...data,
          categoryWorkerRequired: data.categoryWorkerRequired.map((item) => {
            return {
              ...item,
              subSkills: item.subSkills.map((skill) => {
                return {
                  name: skill.name,
                  sub_id: skill.sub_id,
                  months: skill.days,
                  workers: skill.workers,
                };
              }),
            };
          }),
        };
      }
      data = {
        ...tempData,
        tenure: {
          ...tempData?.tenure,
          warning: tempData?.tenure?.value === "",
        },
      };
      const allWorkersZero = data?.categoryWorkerRequired?.every((category) =>
        category.subSkills?.every((subSkill) => subSkill.workers === 0)
      );
      if (allWorkersZero) {
        return toast.error("Select the category of worker");
      }
      if (!data?.tenure?.warning) {
        setStep(2);
      }
    } else if (step === 2) {
      data = {
        ...data,
        country: {
          ...data?.country,
          warning: data?.country?.value === "",
        },
        accomodation: {
          ...data?.accomodation,
          warning: data?.accomodation?.value === "",
        },
        transportation: {
          ...data?.transportation,
          warning: data?.transportation?.value === "",
        },
        message: {
          ...data?.message,
          warning: data?.message?.value === "",
        },
        name: {
          ...data?.name,
          warning:
            data?.name?.value === "" ||
            !/^[A-Za-z\s]+$/.test(data?.name?.value),
        },
        email: {
          ...data?.email,
          warning: globalValidationFunction().validateEmail(data?.email?.value),
        },
        contactNumber: {
          ...data?.contactNumber,
          warning: data?.contactNumber?.value === "" ||
          data?.contactNumber?.value.length > 15,
        },
        userType: {
          ...data?.userType,
          warning: data?.userType?.value === "",
        },
      };
      if (
        !data?.accomodation?.warning &&
        !data?.transportation?.warning &&
        !data?.name?.warning &&
        !data?.email?.warning &&
        !data?.contactNumber?.warning &&
        !data?.country?.warning
      ) {
        dispatch(manPowerEnquirySlice.actions.setFormData(formDataValue));

        const subIds = categoryTable?.map((item) => {
          return item?.subSkills
            ?.filter((item) => item.workers > 0)
            .map((filterItem) => filterItem?.sub_id);
        });
        const mergedArray = [].concat(...subIds);
        const body = {
          subSkill: mergedArray,
        };

        // getTheWorkerAsPerCategory with argument body
        const postData = await getTheWorkerAsPerCategory(body);
        if (postData?.data?.data?.length === 0) {
          return alert("data not found");
        }
        dispatch(
          manPowerEnquirySlice.actions.selectCartItem(postData?.data?.data)
        );
        let enquiriesData;

        if (selectedOption === shortTerm) {
          enquiriesData = categoryTable.flatMap((item) =>
            item.subSkills
              .filter((skill) => skill.workers > 0)
              .map((skill) => ({
                skill_name: skill.name,
                sub_id: skill.sub_id,
                workers: skill.workers,
                days: skill.days,
              }))
          );
        } else {
          enquiriesData = categoryTable.flatMap((item) =>
            item.subSkills
              .filter((skill) => skill.workers > 0)
              .map((skill) => ({
                skill_name: skill.name,
                sub_id: skill.sub_id,
                workers: skill.workers,
                months: skill.days,
              }))
          );
        }

        // body to set send to the backend
        const bodyData = {
          email: data?.email?.value,
          fullName: data?.name?.value,
          contactNumber: data?.contactNumber?.value,
          companyName: data?.companyName?.value,
          accommodation: data?.accomodation?.value,
          transportation: data?.transportation?.value,
          message: data?.message?.value,
          city: data?.city?.value,
          country: data?.country?.value,
          user_type: data?.userType?.value,
          // user_ids:
          //   cartSelectedItem &&
          //   Object.keys(cartSelectedItem)
          //     ?.map((item) =>
          //       cartSelectedItem[item]?.filter(
          //         (filterValue) => filterValue.checked
          //       )
          //     )
          //     .flat()
          //     .map((idItem) => idItem?._id),
          enquiries: enquiriesData,
          tenure: data?.tenure?.value,
        };
        if (selectedOption === shortTerm) {
          const postData = await submitManpowerDataDromthecartPage(bodyData);
          if (postData?.status) {
            setOpenPopup(true);
          } else {
            toast.error("Something went wrong");
          }
        } else {
          const postData = await submitManpowerDataDromthecartPage(bodyData);
          if (postData?.status) {
            navigate(pathUrl.workerList);
            dispatch(
              manPowerEnquirySlice.actions.setUserId(postData?.data?.data?._id)
            );
          } else {
            toast.error("Something went wrong");
          }
        }
      }
    }
    setFormDataValue({ ...data });
  };
  // remove the selected category
  // const removeCategory = (index, sub_id) => {
  //   let data = [...formDataValue?.categoryWorkerRequired];
  //   const targetIndex = data[index].subSkills.findIndex(
  //     (skill) => skill.sub_id === sub_id
  //   );
  //   const skillArr = [...data[index]?.subSkills];
  //   skillArr?.splice(targetIndex, 1, {
  //     ...data[index]?.subSkills[targetIndex],
  //     workers: 0,
  //     days: 0,
  //     // hours: 0,
  //   });
  //   data[index] = {
  //     ...data[index],
  //     subSkills: skillArr,
  //   };
  //   setFormDataValue((prev) => ({
  //     ...prev,
  //     categoryWorkerRequired: data,
  //   }));
  //   dispatch(
  //     manPowerEnquirySlice.actions.categoryTableDataIncrementDecrement(data)
  //   );
  // };

  // get the country data and city and manipulate them as per the requirememnt
  const getCountryCity = () => {
    const countryData = fetchCountriesAndCities();
    console.log(countryData, "countryData");

    // Create a set of top countries for fast lookup
    const topCountriesSet = new Set([
      countryOnTopList.uae,
      countryOnTopList.saudiArabia,
      countryOnTopList.oman,
      countryOnTopList.kuwait,
      countryOnTopList.bahrain,
      countryOnTopList.qatar,
    ]);

    // Filter out the top countries and collect them separately
    const filterArr = [];
    const remainingData = countryData.filter((item) => {
      if (topCountriesSet.has(item.country)) {
        filterArr.push(item);
        return false;
      }
      return true;
    });

    // Specify the order of top countries as required
    const orderedTopCountries = [
      countryOnTopList.uae,
      countryOnTopList.saudiArabia,
      countryOnTopList.oman,
      countryOnTopList.kuwait,
      countryOnTopList.bahrain,
      countryOnTopList.qatar,
    ];

    // Arrange the top countries in the required order
    const orderedFilterArr = orderedTopCountries.map((country) =>
      filterArr.find((item) => item.country === country)
    );

    // Combine the ordered top countries with the remaining data
    const data = [...orderedFilterArr];

    setCountriesWithCities(data);
  };

  // get the cities of the particular country
  const getTheCityOfParticularCountry = () => {
    const filterValue = countriesWithCities?.filter(
      (countryItem) => countryItem?.country === formDataValue?.country?.value
    );
    setCity(filterValue[0]?.cities);
  };

  // get the categorylist  from the backend
  const getCategoryFromBackend = async () => {
    let getList = await getCategory();
    // manipulate the data as per the frontend requirement
    if (getList?.status === true && getList?.data?.length > 0) {
      const data = getList?.data?.map((item) => {
        const { skillName, subSkills } = item;
        return {
          ...item,
          skillName,
          subSkills: subSkills.map((item, index) => {
            return {
              ...item,
              name: item.name,
              workers: 0,
              days: 0,
            };
          }),
        };
      });
      if (
        !categoryTable?.some((item) => item.workers > 0) ||
        getList?.data?.length > categoryTable?.length
      ) {
        dispatch(
          manPowerEnquirySlice.actions.categoryTableDataIncrementDecrement(data)
        );
      }
    }
  };

  const onBlurPhoneHandler = (valid) => {
    if (!valid) {
      setFormDataValue({
        ...formDataValue,
        contactNumber: {
          ...formDataValue?.contactNumber,
          warning: true,
        },
      });
    }
  };

  const onConfirmHandler = () => {
    getCategoryFromBackend();
    dispatch(manPowerEnquirySlice.actions.slectedCategoriesCart([]));
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedOption(prevSelectedOption);
  };
  if (loading) {
    return <Loader />; // Show loader while loading
  }
  return (
    <>
      <HandleHash location={location} />
      <div className="home_wrapper">
        <section className="carosel_wrapper">
          <Carousel
            wrap={true}
            interval={2000} // Set the interval for sliding
            pause={isButtonHovered ? "hover" : false} // Pause carousel on hover if button is hovered
          >
            {banners.map((item, index) => (
              <Carousel.Item key={index}>
                <img
                  className="img-fluid"
                  src={item?.banner_picture}
                  alt="carousel image"
                />
                <Carousel.Caption>
                  <div className="multisteper_wrapper">
                    <h3 className="easily_find_heading">{item?.title1}</h3>
                    <h3 className="great_worker_heading">{item?.title2}</h3>
                    <p
                      className="paragraph"
                      dangerouslySetInnerHTML={{ __html: item?.content }}
                    ></p>
                    <a
                      className="btn-search hover-btn"
                      href="#search_manpower"
                      onMouseEnter={handleMouseEnter} // Handle hover
                      onMouseLeave={handleMouseLeave} // Handle mouse leave
                    >
                      <span>Search Manpower</span>
                    </a>
                  </div>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        </section>

        <section id="search_manpower">
          <div className="searchmanpower py-100">
            <div className="container">
              <div className="col-lg-12">
                <div className="multiform_form_data ps-3">
                  <h1
                    className="multisteper_heading pb-3 text-center" id="search-manpower-heading"
                    style={{ fontFamily: "Opificio" }}
                  >
                    Search Manpower
                  </h1>

                  <h5>Select Duration</h5>

                  {step === 1 && (


                    <div className="d-flex py-3 c-gap-10 short-terms w-50 ">

                      <RadioComp
                        title="Short term"
                        subTitle="(Less than 6 months)"
                        name="tenure"
                        checked={selectedOption === shortTerm}
                        value={shortTerm}
                        onChange={handleRadioChange}
                      />
                      <RadioComp
                        title="Long term"
                        name="tenure"
                        value={longTerm}
                        checked={selectedOption === longTerm}
                        subTitle="(More than 6 months)"
                        onChange={handleRadioChange}
                      />
                      <ConfirmationPupup
                        open={open}
                        heading={
                          <span>
                            Do you want to switch?
                            <br /> If yes, your cart will be cleared
                          </span>
                        }
                        setOpen={setOpen}
                        onConfirm={onConfirmHandler}
                        handleClose={handleClose}
                        btnText="Yes"
                        noBtn="No"
                      />
                    </div>
                  )}
                  {/* {formDataValue?.tenure?.warning && (
                    <span className="text-danger">
                      Please select tenure first
                    </span>
                  )} */}
                  <h5>Categories of Workers Required</h5>

                  <div className="tabs-section">
                    <div className="steper_data">
                      {step === 1 ? (
                        <form>
                          <CategoriesSection
                            categories={categoryTable}
                            tenure={selectedOption}
                            selectedCart={selectedCart}
                            onSubmit={(e, data) => {
                              onSubmit(e, data);
                            }}
                            setFormDataValue={setFormDataValue}
                          />
                        </form>
                      ) : step === 2 ? (
                        <>
                          <form>
                            <Row>
                              <Col>
                                <Form.Group
                                  className="mb-3 position-relative"
                                  controlId="formBasicContactNumber"
                                >
                                  <Form.Label
                                    style={{
                                      position: "absolute",
                                      top: "13px",
                                      left: "16px",
                                      fontWeight: "bold",
                                    }}
                                    className="label-size"
                                  >
                                    Work Location
                                    <IoInformationCircleOutline
                                      className="info_icon"
                                      data-tooltip-id="tooltip-1"
                                    />
                                  </Form.Label>
                                  <Form.Select
                                    style={{
                                      height: "80px",
                                      paddingTop: "38px",
                                    }}
                                    type="text"
                                    name="country"
                                    placeholder="Enter Country"
                                    required
                                    autoComplete="off"
                                    value={formDataValue?.country?.value}
                                    onChange={onChange}
                                  >
                                    <option value="">
                                      Choose the work location
                                    </option>
                                    {countriesWithCities &&
                                      countriesWithCities?.map((item) => (
                                        <option value={item?.country}>
                                          {item?.country}
                                        </option>
                                      ))}
                                  </Form.Select>
                                  {formDataValue?.country?.warning && (
                                    <span className="text-danger">
                                      Select the work location
                                    </span>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group
                                  className="mb-3 position-relative"
                                  controlId="formBasicCompanyName"
                                >
                                  <Form.Label
                                    style={{
                                      position: "absolute",
                                      top: "13px",
                                      left: "16px",
                                      fontWeight: "bold",
                                    }}
                                    className="label-size"
                                  >
                                    Work City{" "}
                                    <IoInformationCircleOutline
                                      className="info_icon"
                                      data-tooltip-id="tooltip-2"
                                    />
                                  </Form.Label>
                                  <Form.Control
                                    style={{
                                      height: "80px",
                                      paddingTop: "38px",
                                    }}
                                    type="text"
                                    name="city"
                                    placeholder="Enter City"
                                    required
                                    autoComplete="off"
                                    value={formDataValue?.city?.value}
                                    // disabled={
                                    //   formDataValue?.country?.value === ""
                                    // }
                                    onChange={onChange}
                                    list="cityOptions" // Link to the datalist
                                  />
                                  <datalist id="cityOptions">
                                    {city?.map((item, index) => (
                                      <option value={item} key={index} />
                                    ))}
                                  </datalist>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="">
                                <Form.Group
                                  className="mb-3 position-relative border shadow bg-white p-2 mb-2"
                                  controlId="accomodation"
                                >
                                  <Form.Label className="label-size fw-bold">
                                    Will you provide accomodation?{" "}
                                    <IoInformationCircleOutline
                                      className="info_icon"
                                      data-tooltip-id="tooltip-3"
                                    />
                                  </Form.Label>
                                  <div className=" d-flex gap-3 radio_custom">
                                    <Form.Check
                                      type="radio"
                                      label="Yes"
                                      name="accomodation"
                                      id="accomodationYes"
                                      value="yes"
                                      checked={
                                        formDataValue?.accomodation?.value ===
                                        "yes"
                                      }
                                      onChange={onChange}
                                    />
                                    <Form.Check
                                      type="radio"
                                      label="No"
                                      name="accomodation"
                                      id="accomodationNo"
                                      value="no"
                                      checked={
                                        formDataValue?.accomodation?.value ===
                                        "no"
                                      }
                                      onChange={onChange}
                                    />
                                  </div>
                                  {formDataValue?.accomodation?.warning && (
                                    <span className="text-danger">
                                      Select Accomodation
                                    </span>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col className="">
                                <Form.Group
                                  controlId="transportation"
                                  className="mb-3 position-relative border shadow bg-white p-2 mb-2"
                                >
                                  <Form.Label className="label-size  fw-bold">
                                    Will you provide transportation?{" "}
                                    <IoInformationCircleOutline
                                      className="info_icon"
                                      data-tooltip-id="tooltip-4"
                                    />
                                  </Form.Label>
                                  <div className=" d-flex gap-3 radio_custom">
                                    <Form.Check
                                      type="radio"
                                      label="Yes"
                                      name="transportation"
                                      id="transportationYes"
                                      value="yes"
                                      checked={
                                        formDataValue?.transportation?.value ===
                                        "yes"
                                      }
                                      onChange={onChange}
                                    />
                                    <Form.Check
                                      type="radio"
                                      label="No"
                                      name="transportation"
                                      id="transportationNo"
                                      value="no"
                                      checked={
                                        formDataValue?.transportation?.value ===
                                        "no"
                                      }
                                      onChange={onChange}
                                    />
                                  </div>
                                  {formDataValue?.transportation?.warning && (
                                    <span className="text-danger">
                                      Select Transportation
                                    </span>
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col>
                                <Form.Group
                                  className="mb-3 position-relative"
                                  controlId="formBasicName"
                                >
                                  <Form.Label
                                    style={{
                                      position: "absolute",
                                      top: "13px",
                                      left: "16px",
                                      fontWeight: "bold",
                                    }}
                                    className="label-size"
                                  >
                                    Name{" "}
                                    <IoInformationCircleOutline
                                      className="info_icon"
                                      data-tooltip-id="tooltip-6"
                                    />
                                  </Form.Label>
                                  <Form.Control
                                    style={{
                                      height: "80px",
                                      paddingTop: "38px",
                                    }}
                                    type="text"
                                    name="name"
                                    placeholder="Enter your name here"
                                    required
                                    autoComplete="off"
                                    onChange={(e) => onChange(e)}
                                    value={formDataValue?.name?.value}
                                  />
                                  {formDataValue?.name?.warning && (
                                    <span className="text-danger">
                                      {formDataValue?.name?.value === ""
                                        ? "Enter the name"
                                        : "Special characters and numbers are not allowed"}
                                    </span>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3 position-relative"
                                >
                                  <Form.Label
                                    style={{
                                      position: "absolute",
                                      top: "13px",
                                      left: "16px",
                                      fontWeight: "bold",
                                    }}
                                    className="label-size"
                                  >
                                    Email{" "}
                                    <IoInformationCircleOutline
                                      className="info_icon"
                                      data-tooltip-id="tooltip-7"
                                    />
                                  </Form.Label>
                                  <Form.Control
                                    style={{
                                      height: "80px",
                                      paddingTop: "38px",
                                    }}
                                    type="email"
                                    name="email"
                                    placeholder="Enter your email here"
                                    required
                                    autoComplete="off"
                                    value={formDataValue?.email?.value}
                                    onChange={onChange}
                                  />
                                  {formDataValue?.email?.warning && (
                                    <span className="text-danger">
                                      Enter valid email
                                    </span>
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <PhoneInputComp2
                                  dataTooltipId="tooltip-8"
                                  onChange={handlePhoneChange}
                                  onBlurHandler={onBlurPhoneHandler}
                                  name="contactNumber"
                                  id="contactNumber"
                                  className="pt-3"
                                  placeholder="Enter your contact number"
                                  value={formDataValue?.contactNumber?.value}
                                  // error={formDataValue?.contactNumber?.warning}
                                />
                                {formDataValue?.contactNumber?.warning && (
                                    <span className="text-danger">
                                      {formDataValue?.contactNumber?.value === ""
                                        ? "Enter the number"
                                        : "Enter a valid number"}
                                    </span>
                                  )}
                              </Col>
                              <Col md={6}>
                                <Form.Group
                                  className="mb-3 position-relative"
                                  controlId="formBasicCompanyName"
                                >
                                  <Form.Label
                                    style={{
                                      position: "absolute",
                                      top: "13px",
                                      left: "16px",
                                      fontWeight: "bold",
                                    }}
                                    className="label-size"
                                  >
                                    Company Name{" "}
                                    <IoInformationCircleOutline
                                      className="info_icon"
                                      data-tooltip-id="tooltip-9"
                                    />
                                    <span className="fontnormal">
                                      (Optional)
                                    </span>
                                  </Form.Label>
                                  <Form.Control
                                    style={{
                                      height: "80px",
                                      paddingTop: "38px",
                                    }}
                                    type="text"
                                    name="companyName"
                                    placeholder="Enter your company name"
                                    required
                                    autoComplete="off"
                                    onChange={onChange}
                                    value={formDataValue?.companyName?.value}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Form.Group
                                  className="mb-3 position-relative textarea-field"
                                  controlId="formBasicCompanyName"
                                >
                                  <Form.Control
                                    as="textarea"
                                    style={{ height: "120px" }}
                                    type="text"
                                    name="message"
                                    placeholder="Message"
                                    autoComplete="off"
                                    value={formDataValue?.message?.value}
                                    onChange={onChange}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>

                            <div className="d-flex justify-content-between">
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  setStep(1);
                                  setIsBack(true);
                                }}
                                className="back-btn"
                              >
                                <div className="d-flex justify-content-between align-items-center">
                                  <FaArrowLeft size={16} className="ms-3" />
                                  Back
                                </div>
                              </button>
                              <button
                                onClick={(e) => {
                                  onSubmit(e);
                                }}
                                className="steper_continue_button-in-btw"
                              >
                                <div className="d-flex justify-content-between align-items-center">
                                  Submit{" "}
                                  <FaArrowRight size={16} className="ms-3" />
                                </div>
                              </button>
                            </div>
                          </form>
                          <ThanksModal
                            open={openPopup}
                            setOpen={setOpenPopup}
                            content={
                              "Thank you for visiting our website and submittng your enquiry. One of our  sales specialist will contact you  within 24 hrs.      "
                            }
                            continueBtnFun={() => {
                              setStep(1);
                              setOpenPopup(false);
                              dispatch(
                                manPowerEnquirySlice.actions.resetToTheInitialState()
                              );
                              getCategoryFromBackend();
                            }}
                            btnText={"Ok"}
                          />
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <SkillSetSection skillList={skillList} />
        <QualifiedWorkerSection />
        <IndustryServe />

        <ClientReview reviews={reviews} />

        <IndustryInsights />
        <Tooltip
          id="tooltip-1"
          content="Please enter the address of the work location."
        />
        <Tooltip
          id="tooltip-2"
          content="Please enter the city where the work is located."
        />
        <Tooltip
          id="tooltip-3"
          content="Will you provide accommodation for the workers? Select Yes or No."
        />
        <Tooltip
          id="tooltip-4"
          content="Will you provide transportation for the workers? Select Yes or No."
        />
        {/* <Tooltip
        id="tooltip-5"
        content="Select the categories of workers needed for the project."
      /> */}
        <Tooltip id="tooltip-6" content="Please enter your full name." />
        <Tooltip
          id="tooltip-7"
          content="Please enter a valid email address for contact."
        />
        <Tooltip
          id="tooltip-8"
          content="Please enter a valid contact number with country code."
        />
        <Tooltip
          id="tooltip-9"
          content="Please enter the name of your company."
        />
        <Footer />
      </div>
    </>
  );
}

import React, { useEffect, useState, useCallback } from "react";
import { FaArrowRight, FaRegTrashAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { manPowerEnquirySlice } from "../redux/manpowerEnquiry/manpowerEnquirySLice";
import ConfirmationPupup from "./confirmPupUp";
import Swal from "sweetalert2";

const CategoriesSection = ({
  categories,
  tenure,
  selectedCart,
  onSubmit,
  setFormDataValue,
}) => {
  const [data, setData] = useState(categories);
  const [cart, setCart] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(categories)) {
      setData(categories);
    }
  }, [categories]);

  useEffect(() => {
    setCart(selectedCart);
  }, [selectedCart]);

  const handleInputChange = useCallback((skillName, subSkill, field, value) => {
    const updatedValue = value === "" ? "" : Number(value);

    setInputValues((prev) => ({
      ...prev,
      [`${skillName}-${subSkill}-${field}`]: value,
    }));

    setData((prev) =>
      prev.map((category) =>
        category.skillName === skillName
          ? {
              ...category,
              subSkills: category.subSkills.map((sub) =>
                sub.name === subSkill ? { ...sub, [field]: updatedValue } : sub
              ),
            }
          : category
      )
    );
  }, []);

  const handleAddToCart = useCallback(
    async (skillName, subSkill) => {
      if (tenure === "") {
        setFormDataValue((prev) => ({
          ...prev,
          tenure: { value: "", warning: true },
        }));
        // await Swal.fire("Error!", " ", "error");
        await Swal.fire({
          text: "Please select short term or long term",
          icon: "warning",
          confirmButtonText: "OK",
        });
        return;
      }

      setFormDataValue((prev) => ({
        ...prev,
        tenure: { value: tenure, warning: false },
      }));

      const workers = inputValues[`${skillName}-${subSkill}-workers`] || "";
      const days = inputValues[`${skillName}-${subSkill}-days`] || "";

      if (workers && days) {
        setCart((prev) => {
          const existingIndex = prev.findIndex(
            (item) => item.skillName === skillName && item.subSkill === subSkill
          );

          const updatedCart = [...prev];
          if (existingIndex !== -1) {
            updatedCart[existingIndex] = {
              ...updatedCart[existingIndex],
              workers: Number(workers),
              days: Number(days),
            };
          } else {
            updatedCart.push({
              skillName,
              subSkill,
              workers: Number(workers),
              days: Number(days),
            });
          }

          dispatch(
            manPowerEnquirySlice.actions.slectedCategoriesCart(updatedCart)
          );
          return updatedCart;
        });

        setInputValues((prev) => ({
          ...prev,
          [`${skillName}-${subSkill}-workers`]: "",
          [`${skillName}-${subSkill}-days`]: "",
        }));
      }
    },
    [inputValues, dispatch, tenure, setFormDataValue]
  );

  const handleRemoveFromCart = useCallback(
    (index) => {
      const removedItem = cart[index];
      const updatedCart = cart.filter((_, i) => i !== index);

      setCart(updatedCart);
      dispatch(manPowerEnquirySlice.actions.slectedCategoriesCart(updatedCart));

      setData((prev) =>
        prev.map((category) =>
          category.skillName === removedItem.skillName
            ? {
                ...category,
                subSkills: category.subSkills.map((sub) =>
                  sub.name === removedItem.subSkill
                    ? { ...sub, workers: 0, days: 0 }
                    : sub
                ),
              }
            : category
        )
      );
    },
    [cart, dispatch]
  );

  const handleCartInputChange = useCallback(
    (index, field, value) => {
      const updatedValue = value === "" ? "" : Number(value);
      const updatedCart = cart.map((item, i) =>
        i === index ? { ...item, [field]: updatedValue } : item
      );

      setCart(updatedCart);
      dispatch(manPowerEnquirySlice.actions.slectedCategoriesCart(updatedCart));

      const updatedCartItem = updatedCart[index];
      setData((prev) =>
        prev.map((category) =>
          category.skillName === updatedCartItem.skillName
            ? {
                ...category,
                subSkills: category.subSkills.map((sub) =>
                  sub.name === updatedCartItem.subSkill
                    ? { ...sub, [field]: updatedValue }
                    : sub
                ),
              }
            : category
        )
      );
    },
    [cart, dispatch]
  );

  const isAddButtonDisabled = useCallback(
    (skillName, subSkill) => {
      const workers = inputValues[`${skillName}-${subSkill}-workers`] || "";
      const days = inputValues[`${skillName}-${subSkill}-days`] || "";
      return workers === "" || days === "";
    },
    [inputValues]
  );

  useEffect(() => {
    dispatch(
      manPowerEnquirySlice.actions.categoryTableDataIncrementDecrement(data)
    );
  }, [data, dispatch]);

  const handleClose = () => {
    setOpen(false);
  };

  const onInputClickHandler = async () => {
    if (tenure === "") {
      setFormDataValue((prev) => ({
        ...prev,
        tenure: { value: "", warning: true },
      }));
      await Swal.fire({
        text: "Please select short term or long term",
        icon: "warning",
        confirmButtonText: "OK",
      });
    }
  };

  const onBeforeInputHandler = (e) => {
    const invalidChars = ["-", "+"];
    if (invalidChars.includes(e.data)) {
      e.preventDefault();
    }
  };

  return (
    <Tabs>
      <TabList>
        {data.map(({ skillName }, index) => (
          <Tab key={index}>{skillName}</Tab>
        ))}
      </TabList>

      {data.map(({ skillName, subSkills }, index) => (
        <TabPanel key={index}>
          <div className="row">
            <div className="col-lg-8">
              <div className="catgories-list">
                {subSkills.map((subSkill, subIndex) => (
                  <div className="categories-card" key={subIndex}>
                    <h5>{subSkill.name}</h5>
                    <div className="catgories-flex">
                      <div className="worker-input">
                        <input
                          min={1}
                          placeholder="0"
                          onClick={onInputClickHandler}
                          type="number"
                          className="small-input"
                          value={
                            inputValues[
                              `${skillName}-${subSkill.name}-workers`
                            ] || ""
                          }
                          onBlur={(e) =>
                            handleInputChange(
                              skillName,
                              subSkill.name,
                              "workers",
                              e.target.value.replace(/[^0-9]/g, "")
                            )
                          }
                          onChange={(e) =>
                            handleInputChange(
                              skillName,
                              subSkill.name,
                              "workers",
                              e.target.value
                            )
                          }
                          onBeforeInput={onBeforeInputHandler}
                        />
                        <p>
                          Required
                          <br /> Worker
                        </p>
                      </div>
                      <div className="worker-input">
                        <input
                          min={1}
                          placeholder="0"
                          onClick={onInputClickHandler}
                          type="number"
                          className="small-input"
                          value={
                            inputValues[`${skillName}-${subSkill.name}-days`] ||
                            ""
                          }
                          onBlur={(e) =>
                            handleInputChange(
                              skillName,
                              subSkill.name,
                              "days",
                              e.target.value.replace(/[^0-9]/g, "")
                            )
                          }
                          onChange={(e) =>
                            handleInputChange(
                              skillName,
                              subSkill.name,
                              "days",
                              e.target.value
                            )
                          }
                          onBeforeInput={onBeforeInputHandler}
                        />
                        <p>
                          Required <br />
                          {tenure === "long_term" ? "Months" : "Days"}
                        </p>
                      </div>
                      <div className="worker-add">
                        <button
                          type="button"
                          className="add-btn-worker"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleAddToCart(skillName, subSkill.name);
                          }}
                          disabled={
                            tenure === "" ||
                            isAddButtonDisabled(skillName, subSkill.name)
                          }
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="cart-section">
                <h4>My Selections</h4>
                {cart.length > 0 ? (
                  <div className="cart-table-design">
                    <table>
                      <thead>
                        <tr>
                          <th>Worker Type</th>
                          <th>Worker</th>
                          <th>{tenure === "long_term" ? "Months" : "Days"}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {cart.map((item, index) => (
                          <tr key={index} className="tr_row">
                            <td>
                              <div className="d-flex flex-column">
                                <span style={{ fontWeight: "bold" }}>
                                  {item.skillName}
                                </span>
                                <span>{item.subSkill}</span>
                              </div>
                            </td>
                            <td>
                              <input
                                type="number"
                                value={item.workers || ""}
                                onChange={(e) =>
                                  handleCartInputChange(
                                    index,
                                    "workers",
                                    e.target.value
                                  )
                                }
                                onBeforeInput={onBeforeInputHandler}
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                value={item.days || ""}
                                onChange={(e) =>
                                  handleCartInputChange(
                                    index,
                                    "days",
                                    e.target.value
                                  )
                                }
                                onBeforeInput={onBeforeInputHandler}
                              />
                            </td>
                            <td >
                              <FaRegTrashAlt style={{ cursor: "pointer" }} onClick={() => setOpen(true)} />
                              <ConfirmationPupup
                                open={open}
                                heading={<span>Do you want to delete?</span>}
                                setOpen={setOpen}
                                onConfirm={() => handleRemoveFromCart(index)}
                                handleClose={handleClose}
                                btnText="Yes"
                                noBtn="No"
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="cart_not_found">No Selected Items</div>
                )}
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    onSubmit(e);
                    const section = document.getElementById('search-manpower-heading');
                      if (section) {
                        section.scrollIntoView({ behavior: 'smooth' });
                      }
                  }}
                  className="steper_continue_button"
                  disabled={cart.length === 0}
                >
                  Continue <FaArrowRight className="ms-3" />
                </button>
              </div>
            </div>
          </div>
        </TabPanel>
      ))}
    </Tabs>
  );
};

export default CategoriesSection;

import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { LogoSvg } from "../../assests/svg";

import {
  MdSpaceDashboard,
  MdManageAccounts,
  MdNotifications,
} from "react-icons/md";
import {
  CgImage,
  CgNotes,
  CgNotifications,
  CgPhone,
  CgUserList,
} from "react-icons/cg";
import { BiCategory } from "react-icons/bi";
import { RiPagesLine } from "react-icons/ri";
import { IoMenu } from "react-icons/io5";
import gulfLogo from "../../assests/admin-images/gulfkaam/gulf-kaam-logo.png";
import { adminPathUrl, pathUrl } from "../../shared/pathUrl";
import "./sidebar.css";

function Sidebar({ activeTab }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const permissions = loginData?.permissions;

  return (
    <div className={`sidebar ${collapsed ? "collapsed" : "expanded"}`}>
      <div className="d-flex flex-column h-100">
        <div className="header-logo-worker-list ps-5">
          <span
            style={{ cursor: "pointer" }}
            // onClick={() => navigate(pathUrl.homePage)}
          >
            {activeTab === "gulfKaam" || activeTab === "dataLake" ? (
              <img src={gulfLogo} alt="Gulf Logo" />
            ) : (
              LogoSvg()
            )}
          </span>
        </div>
        <div className="menu-trigger">
          <button
            className="sb-button"
            onClick={() => setCollapsed(!collapsed)}
          >
            <IoMenu />
          </button>
        </div>
        {activeTab === "wePlaced" && (
          <ul className="nav nav-pills pt-4 px-2 sidebar_links" id="menu">
            <li>
              <Link
                to={adminPathUrl.dashboard}
                className={`nav-link ${
                  location.pathname === adminPathUrl.dashboard ? "active" : ""
                }`}
              >
                <span className="me-1">
                  <MdSpaceDashboard />
                </span>
                {!collapsed && <span>Dashboard</span>}
              </Link>
            </li>
            {permissions?.includes("manageWorkforceEnquiry") && (
              <li>
                <Link
                  to={adminPathUrl.manageManpower}
                  className={`nav-link ${
                    location.pathname === adminPathUrl.manageManpower ||
                    location.pathname.includes(
                      adminPathUrl.manageManpowerDetail
                    )
                      ? "active"
                      : ""
                  }`}
                >
                  <span className="me-1">
                    <CgNotes />
                  </span>
                  {!collapsed && <span>Manage WorkForce Enquiry</span>}
                </Link>
              </li>
            )}
            {/* {permissions?.includes("manageAdminRole") && (
              <li>
                <Link
                  to={adminPathUrl.manageUsers}
                  className={`nav-link ${
                    location.pathname === adminPathUrl.manageUsers ||
                    location.pathname === adminPathUrl.addUsers ||
                    location.pathname.includes(adminPathUrl.updateUsers)
                      ? "active"
                      : ""
                  }`}
                >
                  <span className="me-1">
                    <CgUserList />
                  </span>
                  {!collapsed && <span>Manage Admin Role</span>}
                </Link>
              </li>
            )} */}
            {permissions?.includes("manageBanner") && (
              <li>
                <Link
                  to={adminPathUrl.banner}
                  className={`nav-link ${
                    location.pathname === adminPathUrl.banner ? "active" : ""
                  }`}
                >
                  <span className="me-1">
                    <CgImage />
                  </span>
                  {!collapsed && <span>Manage Banner</span>}
                </Link>
              </li>
            )}
            {permissions?.includes("manageCategory") && (
              <li>
                <Link
                  to={adminPathUrl.category}
                  className={`nav-link ${
                    location.pathname === adminPathUrl.category ||
                    location.pathname === adminPathUrl.addCategory ||
                    location.pathname === adminPathUrl.updateCategory
                      ? "active"
                      : ""
                  }`}
                >
                  <span className="me-1">
                    <BiCategory />
                  </span>
                  {!collapsed && <span>Manage Category</span>}
                </Link>
              </li>
            )}
            {permissions?.includes("cms") && (
              <li className="sidebar_dropDown">
                <Dropdown autoClose="outside">
                  <Dropdown.Toggle id="dropdown-basic">
                    <span className="me-1">
                      <RiPagesLine />
                    </span>
                    {!collapsed && <span>CMS Pages</span>}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <ul className="nav nav-pills ps-4">
                      <li>
                        <Link
                          to="/admin/manage-Homepage"
                          className={`nav-link ${
                            location.pathname === "/admin/manage-Homepage"
                              ? "active"
                              : ""
                          }`}
                        >
                          <RiPagesLine />{" "}
                          <span className="small-hide"> Manage Homepage</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/admin/manage-privacy-policy"
                          className={`nav-link ${
                            location.pathname === "/admin/manage-privacy-policy"
                              ? "active"
                              : ""
                          }`}
                        >
                          <RiPagesLine />{" "}
                          <span className="small-hide">
                            {" "}
                            Manage Privacy Policy
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={adminPathUrl.manageBlogs}
                          className={`nav-link ${
                            location.pathname === adminPathUrl.manageBlogs ||
                            location.pathname === adminPathUrl.addblogs ||
                            location.pathname.includes(adminPathUrl.updateBlogs)
                              ? "active"
                              : ""
                          }`}
                        >
                          <RiPagesLine />{" "}
                          <span className="small-hide"> Manage Blogs</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={adminPathUrl.manageTermsConditions}
                          className={`nav-link ${
                            location.pathname ===
                            adminPathUrl.manageTermsConditions
                              ? "active"
                              : ""
                          }`}
                        >
                          <RiPagesLine />{" "}
                          <span className="small-hide">
                            {" "}
                            Terms & Conditions
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={adminPathUrl.manageSkillSet}
                          className={`nav-link ${
                            location.pathname === adminPathUrl.manageSkillSet ||
                            location.pathname === adminPathUrl.editSkillSet
                              ? "active"
                              : ""
                          }`}
                        >
                          <RiPagesLine />{" "}
                          <span className="small-hide"> Manage Skillset</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={adminPathUrl.manageReviews}
                          className={`nav-link ${
                            location.pathname === adminPathUrl.manageReviews ||
                            location.pathname === adminPathUrl.addReviews ||
                            location.pathname.includes(
                              adminPathUrl.updateReviews
                            )
                              ? "active"
                              : ""
                          }`}
                        >
                          <RiPagesLine />{" "}
                          <span className="small-hide"> Manage Reviews</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={adminPathUrl.aboutUs}
                          className={`nav-link ${
                            location.pathname === adminPathUrl.aboutUs
                              ? "active"
                              : ""
                          }`}
                        >
                          <RiPagesLine />{" "}
                          <span className="small-hide"> About Us</span>
                        </Link>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            )}
            {permissions?.includes("contactUs") && (
              <li>
                <Link
                  to={adminPathUrl.wePlacedContactUs}
                  className={`nav-link ${
                    location.pathname === adminPathUrl.wePlacedContactUs
                      ? "active"
                      : ""
                  }`}
                >
                  <span className="me-1">
                    <CgPhone />
                  </span>
                  {!collapsed && <span>Contact Us</span>}
                </Link>
              </li>
            )}
          </ul>
        )}
        {activeTab === "gulfKaam" && (
          <ul
            className="nav nav-pills pt-4 flex-column px-2 sidebar_links"
            id="menu"
          >
            <li>
              <Link
                to={adminPathUrl.gulfDashboard}
                className={`nav-link ${
                  location.pathname === adminPathUrl.gulfDashboard
                    ? "active"
                    : ""
                }`}
              >
                <span className="me-1">
                  <MdSpaceDashboard />
                </span>{" "}
                {!collapsed && <span>Dashboard</span>}
              </Link>
            </li>
            {/* <li>
              <Link
                to={adminPathUrl.gulfmanageUsers}
                className={`nav-link ${location.pathname === adminPathUrl.gulfmanageUsers ||
                  location.pathname === adminPathUrl.gulfaddUsers ||
                  location.pathname.includes(adminPathUrl.gulfupdateUsers) ? "active" : ""}`}
              >
                <span className="me-1">
                  <CgUserList />
                </span>{" "}
                {!collapsed && <span>Manage Admin Role</span>}
              </Link>
            </li> */}
            {permissions?.includes("manageGulfWorkforce") && (
              <li>
                <Link
                  to={adminPathUrl.gulfWorkforce}
                  className={`nav-link ${
                    location.pathname === adminPathUrl.gulfWorkforce ||
                    location.pathname === adminPathUrl.gulfAddWorkforce ||
                    location.pathname === adminPathUrl.gulfWorkforceDetail
                      ? "active"
                      : ""
                  }`}
                >
                  <span className="me-1">
                    <MdManageAccounts />
                  </span>{" "}
                  {!collapsed && <span>Manage Workforce</span>}
                </Link>
              </li>
            )}
            {permissions?.includes("gulfContactUs") && (
              <li>
                <Link
                  to={adminPathUrl.gulfContact}
                  className={`nav-link ${
                    location.pathname === adminPathUrl.gulfContact
                      ? "active"
                      : ""
                  }`}
                >
                  <span className="me-1">
                    <CgPhone />
                  </span>{" "}
                  {!collapsed && <span>Contact Us</span>}
                </Link>
              </li>
            )}
            {permissions?.includes("notifications") && (
              <li>
                <Link
                  to={adminPathUrl.gulfNotification}
                  className={`nav-link ${
                    location.pathname === adminPathUrl.gulfNotification
                      ? "active"
                      : ""
                  }`}
                >
                  <span className="me-1">
                    <MdNotifications />
                  </span>{" "}
                  {!collapsed && <span>Manage Notifications</span>}
                </Link>
              </li>
            )}
            {permissions?.includes("gulfAppCms") && (
              <li className="sidebar_dropDown">
                <Dropdown autoClose="outside">
                  <Dropdown.Toggle id="dropdown-basic">
                    <span className="me-1">
                      <RiPagesLine />
                    </span>
                    {!collapsed && <span> App CMS Pages</span>}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <ul className="nav nav-pills ps-4">
                      <li>
                        <Link
                          to={adminPathUrl.gulfNewsAtricle}
                          className={`nav-link ${
                            location.pathname ===
                              adminPathUrl.gulfNewsAtricle ||
                            location.pathname === adminPathUrl.gulfaddUsers ||
                            location.pathname ===
                              adminPathUrl.gulfUpdateNewsAtricle
                              ? "active"
                              : ""
                          }`}
                        >
                          <span className="me-1"></span>
                          <RiPagesLine />{" "}
                          <span className="small-hide"> News & Articles</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={adminPathUrl.gulfAppPrivacyPolicy}
                          className={`nav-link ${
                            location.pathname ===
                            adminPathUrl.gulfAppPrivacyPolicy
                              ? "active"
                              : ""
                          }`}
                        >
                          <RiPagesLine />{" "}
                          <span className="small-hide">
                            {" "}
                            Manage Privacy Policy
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={adminPathUrl.gulfapptermsconditions}
                          className={`nav-link ${
                            location.pathname ===
                            adminPathUrl.gulfapptermsconditions
                              ? "active"
                              : ""
                          }`}
                        >
                          <RiPagesLine />{" "}
                          <span className="small-hide">
                            {" "}
                            Manage Terms & Conditions
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={adminPathUrl.gulfFeatures}
                          className={`nav-link ${
                            location.pathname === adminPathUrl.gulfFeatures
                              ? "active"
                              : ""
                          }`}
                        >
                          <RiPagesLine />
                          <span className="small-hide"> Contact Us</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={adminPathUrl.gulfjobLocation}
                          className={`nav-link ${
                            location.pathname === adminPathUrl.gulfjobLocation
                              ? "active"
                              : ""
                          }`}
                        >
                          <RiPagesLine />{" "}
                          <span className="small-hide"> Job Location</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={adminPathUrl.gulfappAbout}
                          className={`nav-link ${
                            location.pathname === adminPathUrl.gulfappAbout
                              ? "active"
                              : ""
                          }`}
                        >
                          <RiPagesLine />{" "}
                          <span className="small-hide"> About Us</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={adminPathUrl.gulfMarketing}
                          className={`nav-link ${
                            location.pathname === adminPathUrl.gulfMarketing
                              ? "active"
                              : ""
                          }`}
                        >
                          <RiPagesLine />{" "}
                          <span className="small-hide"> Marketing</span>
                        </Link>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            )}
            {permissions?.includes("gulfCms") && (
              <li className="sidebar_dropDown">
                <Dropdown autoClose="outside">
                  <Dropdown.Toggle id="dropdown-basic">
                    <span className="me-1">
                      <RiPagesLine />
                    </span>
                    {!collapsed && <span> Website CMS Pages</span>}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <ul className="nav nav-pills ps-4">
                      <li>
                        <Link
                          to={adminPathUrl.gulfWorking}
                          className={`nav-link ${
                            location.pathname === adminPathUrl.gulfWorking
                              ? "active"
                              : ""
                          }`}
                        >
                          <RiPagesLine />{" "}
                          <span className="small-hide"> Gulfkaam Homepage</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={adminPathUrl.gulfhomepagefeature}
                          className={`nav-link ${
                            location.pathname ===
                            adminPathUrl.gulfhomepagefeature
                              ? "active"
                              : ""
                          }`}
                        >
                          <RiPagesLine />{" "}
                          <span className="small-hide"> Gulfkaam Features</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={adminPathUrl.gulflocdetails}
                          className={`nav-link ${
                            location.pathname === adminPathUrl.gulflocdetails
                              ? "active"
                              : ""
                          }`}
                        >
                          <RiPagesLine />{" "}
                          <span className="small-hide">
                            {" "}
                            Location & Details
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={adminPathUrl.gulfworkinginfo}
                          className={`nav-link ${
                            location.pathname === adminPathUrl.gulfworkinginfo
                              ? "active"
                              : ""
                          }`}
                        >
                          <RiPagesLine />{" "}
                          <span className="small-hide">How It Works</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={adminPathUrl.gulfPrivacyPolicy}
                          className={`nav-link ${
                            location.pathname === adminPathUrl.gulfPrivacyPolicy
                              ? "active"
                              : ""
                          }`}
                        >
                          <RiPagesLine />{" "}
                          <span className="small-hide">
                            {" "}
                            Manage Privacy Policy
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={adminPathUrl.gulfmanagetermsconditions}
                          className={`nav-link ${
                            location.pathname ===
                            adminPathUrl.gulfmanagetermsconditions
                              ? "active"
                              : ""
                          }`}
                        >
                          <RiPagesLine />{" "}
                          <span className="small-hide">
                            {" "}
                            Manage Terms & Conditions
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            )}
          </ul>
        )}
        {activeTab === "dataLake" && (
          <ul
            className="nav nav-pills pt-4 flex-column px-2 sidebar_links"
            id="menu"
          >
            <li>
              <Link
                to={adminPathUrl.dataWorkforce}
                className={`nav-link ${
                  location.pathname === adminPathUrl.dataWorkforce ||
                  location.pathname === adminPathUrl.dataLakeWorkforceDetail ||
                  location.pathname === adminPathUrl.addDataWorkforce
                    ? "active"
                    : ""
                }`}
              >
                <span className="me-1">
                  <MdSpaceDashboard />
                </span>
                {!collapsed && <span>Manage Profiles</span>}
              </Link>
            </li>
          </ul>
        )}

        {activeTab === "adminRoles" && (
          <ul
            className="nav nav-pills pt-4 flex-column px-2 sidebar_links"
            id="menu"
          >
            <li>
              <Link
                to={adminPathUrl.manageUsers}
                className={`nav-link ${
                  location.pathname === adminPathUrl.manageUsers ||
                  location.pathname === adminPathUrl.addUsers ||
                  location.pathname === adminPathUrl.updateUsers
                    ? "active"
                    : ""
                }`}
              >
                <span className="me-1">
                  <MdSpaceDashboard />
                </span>
                {!collapsed && <span>Manage Admin Roles</span>}
              </Link>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
}

export default Sidebar;

import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { RiDoubleQuotesL, RiDoubleQuotesR } from "react-icons/ri";
import blog from "../../assests/images/main-feature.png";
import "./index.css";
import { getTheBlogDetail } from "../../services/web/blog/blogServices";

const formatDate = (dateString) => {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

const BlogDetail = () => {
  const { id } = useParams(); // Fetching ID from URL parameter
  const location = useLocation(); // Getting location to access state
  const [blogDetailData, setBlogDetailData] = useState({
    _id: "",
    title: "",
    subTitle: "",
    content: "",
    blog_large_pic: "",
    blog_short_pic: "",
    is_weplaced: false,
    createdAt: "",
    updatedAt: "",
  });

  // Example fallback data for blog detail if state is not available
  const fallbackDetails = {
    title:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium",
    date: "Mar 12, 2024",
    content: `But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete
    account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one
    rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally
    encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself,
    because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.

    To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has
    any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that
    produces no resultant pleasure?

    To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has
    any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that
    produces no resultant pleasure?`,
    quote:
      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
  };

  // Extract blog details from location state or fallback to default values
  const blogDetails = location.state || fallbackDetails;

  // Get image URL from location state or fallback to default image
  const imgUrl = blogDetails.img || blog;

  // Get the blog full detail from the backend
  const getTheBlogDetailData = async () => {
    try {
      const getData = await getTheBlogDetail(id);
      if (getData?.status) {
        setBlogDetailData(getData?.data?.data);
      }
    } catch (error) {
      console.log("exceptional error", error);
    }
  };

  useEffect(() => {
    getTheBlogDetailData();
  }, [id]);

  return (
    <section className="blog-detail mt-5">
      <div className="container">
        <span className="bread-crumb py-0 d-block">
          <span className="bread-crumb-extend-text">
            {/* {blogDetailData?.title} */}
          </span>
        </span>
        <span>{blogDetailData?.createdAt && formatDate(blogDetailData.createdAt)}</span>
        <h3>{blogDetailData?.title}</h3>
        <img
          className="w-100 img-fluid"
          src={blogDetailData?.blog_large_pic || imgUrl}
          alt="Insights Banner"
        />
        <p>{blogDetailData?.content}</p>
        {blogDetailData?.subTitle && (
          <div className="special-note mb-5">
            <RiDoubleQuotesL className="quotes-start" size={55} />
            <span>{blogDetailData?.subTitle}</span>
            <RiDoubleQuotesR size={55} className="quotes-end" />
          </div>
        )}
      </div>
    </section>
  );
};

export default BlogDetail;

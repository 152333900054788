export const pathUrl = {
  homePage: "/",
  aboutUs: "/about",
  workerList: "/worker-list",
  cart: "/cart",
  blog: "/insights",
  blogDetail: "/insights-detail",
  privacyPolicy: "/privacy-policy",
  termCondition: "/term-condition",
};
export const adminPathUrl = {
  login: "/admin",
  forgotPassword: "/admin/forgot-password",
  resetPassword: "/admin/reset-password",
  dashboard: "/admin/dashboard",
  wePlacedContactUs: "/admin/weplacedcontact_us",
  managePrivacyPolicy: "/admin/manage-privacy-policy",
  manageBlogs: "/admin/manage-blogs",
  updateBlogs: "/admin/update-blogs",
  managefaq: "/admin/manage-FAQs",
  addblogs: "/admin/add-blogs",
  manageTermsConditions: "/admin/terms-and-conditions",
  manageReviews: "/admin/manage-reviews",
  addReviews: "/admin/add-reviews",
  updateReviews: "/admin/update-reviews",
  manageManpower: "/admin/man-power-enqiury",
  manageManpowerDetail: "/admin/manage-man-power-detail",
  workforceDetail: "/admin/woker-force-detail",
  category: "/admin/category",
  addCategory: "/admin/add-category",
  updateCategory: "/admin/update-category",
  banner: "/admin/banner",
  notifications: "/admin/notification",
  manageUsers: "/admin/adminRole-manage-users",
  addUsers: "/admin/adminRole-add-users",
  updateUsers: "/admin/adminRole-update-users",
  aboutUs: "/admin/about-us",
  addExecutive: "/admin/add-executive",
  manageExecutive: "/admin/manage-executive",
  editExecutive: "/admin/update-executive",
  manageSkillSet: "/admin/manage-Skillset",
  editSkillSet: "/admin/edit-Skillset",
  manageHomePage: "/admin/manage-Homepage",
  
  

  // gulfKaam pages
  gulfDashboard: "/admin/gulf-dashboard",
  gulfContact: "/admin/gulfcontact_us",
  gulfBlogs: "/admin/gulf-blog",
  gulfAddBlogs: "/admin/add-gulf-blog",
  gulfAddfaqs: "/admin/add-gulf-faqs",
  gulfWorking: "/admin/gulf-working",
  gulfFeatures: "/admin/gulf-features",
  gulfNotification: "/admin/gulfNotification",
  gulfWorkforce: "/admin/gulfWorkforce",
  gulfAddWorkforce: "/admin/gulfadd-Workforce",
  gulfmanageUsers: "/admin/gulf-users",
  gulfaddUsers: "/admin/gulfadd-users",
  gulfupdateUsers: "/admin/gulfupdate-users",
  gulfPrivacyPolicy: "/admin/gulf-privacy-policy",
  gulfAppPrivacyPolicy: "/admin/gulf-App-privacy-policy",

  gulfmanagetermsconditions: "/admin/gulf-terms-and-conditions",
  gulfapptermsconditions: "/admin/gulf-app-terms-and-conditions",

  gulfjobLocation: "admin/gulf-job-location",
  gulfappAbout: "admin/gulf-app-about",

  gulfNewsAtricle: "/admin/gulf-news-articles",
  gulfUpdateNewsAtricle: "/admin/gulf-update-news-articles",
  gulfAddNewsAtricle: "/admin/gulf-add-news-articles",
  gulfManageBlogs: "/admin/gulf-manage-blogs",
  gulfWorkforceDetail: "/admin/gulf-woker-force-detail",
  gulfMarketing: "/admin/gulf-marketing",
  gulfhomepagefeature:"/admin/gulf-homepage-feature",
  gulflocdetails:"/admin/gulf-location-details",
  gulfworkinginfo:"/admin/gulf-working-info",
  //Data lake
  dataWorkforce: "/admin/data-lake-enquiry",
  addDataWorkforce: "/admin/data-lake-add-Workforce",
  dataLakeWorkforceDetail: "/admin/data-lake-woker-force-detail",
  // common
  profile: "/admin/my-profile",
};

import React from "react";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <div className="back_btn">
      <FaArrowLeft onClick={() => navigate(-1)} />
    </div>
  );
};

export default BackButton;

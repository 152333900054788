import React, { useEffect, useState } from "react";
import { IoSearchSharp } from "react-icons/io5";
import DataTableComponent from "../../../components/dataTable/dataTable";
import DeleteModalAdmin from "../../../components/deleteModalAdmin";
import Avatar from "../../../components/Avatar";
import { wePlacedEnquiryService } from "../../../services/admin/manageworkforce/manageWorkEnquiryServices";
import image from "../../../assests/images/Glass Installer.jpg"; // Ensure correct path to image

const ContactUs = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await wePlacedEnquiryService();
        if (res.status) {
          // Filter out entries where is_weplaced is false
          const filteredData = res.data.filter((entry) => !entry.is_weplaced);
          setData(filteredData);
        }
        console.log(res, "res");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleRowClicked = (row) => {
    console.log("Clicked row:", row);
    // Handle navigation or any other action
  };

  const handleCheckboxChange = (selectedRows) => {
    setSelectedRows(selectedRows.selectedRows);
  };

  const userColumns = [
    // {
    //   name: "",
    //   selector: (row) => (
    //     <Avatar imgSrc={image} alt="Profile" imgClass="products-image" />
    //   ),
    // },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Message",
      selector: (row) => row.message,
      sortable: true,
    },
  ];

  return (
    <div className="contact-us-page">
      {/* Search bar if needed */}
      {/* <div className="search-bar-container mb-3">
        <input className="searchbar" type="search" placeholder="Search..." />
        <IoSearchSharp className="searchbar-icon" size={24} />
      </div> */}

      <div className="datatable-section">
      <DataTableComponent
        title="Contact Us "
        data={data}
        columns={userColumns}
        selectedRows={selectedRows}
        clickedrow={handleRowClicked}
        checkboxchange={handleCheckboxChange}
        responsive={true}
      />
      </div>

      {/* Delete modal if needed */}
      {/* <DeleteModalAdmin
        open={deleteModal}
        setOpen={setDeleteModal}
        heading={"Are you sure you want to delete this?"}
      /> */}
    </div>
  );
};

export default ContactUs;

import "react-phone-number-input/style.css";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import { ErrorMessage } from "formik";
import { gulfCountries } from "../utils/helpers";

function PhoneInputComponent({
  id,
  onChange,
  name,
  errName,
  placeholder = "Phone Number (+1234567890)",
  className = "form-control",
  errComp = "div",
  errClass = "error",
  style = { color: "red" },
}) {
  const handlePhoneChange = (value) => {
    if (value) {
      const phoneNumber = parsePhoneNumber(value);
      if (phoneNumber) {
        const countryCode = "+" + phoneNumber.countryCallingCode;
        const number = phoneNumber.nationalNumber;
        onChange({ countryCode, number });
      }
    }
  };
  return (
    <>
      <PhoneInput
        className={className}
        name={name}
        id={id}
        placeholder={placeholder}
        onChange={handlePhoneChange}
        countries={gulfCountries}
        defaultCountry="AE"
      />

      <ErrorMessage
        name={errName}
        component={errComp}
        className={errClass}
        style={style}
      />
    </>
  );
}

export default PhoneInputComponent;
